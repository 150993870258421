import {
    SET_PLAYING,
    SET_AUTO_PLAYED,
    SET_CURRENT_TRACK_ID,
    SET_PLAYLIST,
    SET_SHOW_PLAYER,
    SET_SHOW_DOWNLOAD_PROMPT,
    SET_SHOW_SUBSCRIPTION_PROMPT,
    SET_TOGGLE_SCREEN,
    SET_LISTENER_COUNT,
    SET_TRACK_COUNT,
    SET_PLAYLIST_DETAILS,
    RESET_PLAYLIST_DETAILS,
    SET_CURRENT_PLAYING_CATALOG,
    SET_JOINED_LIVEROOM,
    SET_CURRENT_LIVEROOM_PARTICIPANT_COUNT,
    SET_CURRENT_LIVE_SPEAKERS,
    SET_CURRENT_LIVEROOM,
    SET_PLAYING_RADIO_LISTENER_COUNT,
    SET_SHOW_LOGIN_PROMPT,
    SET_CURRENT_CONTENT_ID,
    SET_ELAPSED_TIME,
} from '@/store/types/player'

export const setPlaying = (payload) => ({
    type: SET_PLAYING,
    payload,
})

export const setAutoPlayed = (payload) => ({
    type: SET_AUTO_PLAYED,
    payload,
})

export const setCurrentTrackId = (payload) => ({
    type: SET_CURRENT_TRACK_ID,
    payload,
})

export const setPlaylist = (payload) => ({
    type: SET_PLAYLIST,
    payload,
})

export const setShowPlayer = (payload) => ({
    type: SET_SHOW_PLAYER,
    payload,
})

export const setShowDownloadPrompt = (payload) => ({
    type: SET_SHOW_DOWNLOAD_PROMPT,
    payload,
})

export const setShowSubscriptionPrompt = (payload) => ({
    type: SET_SHOW_SUBSCRIPTION_PROMPT,
    payload,
})

export const setToggleScreen = (payload) => ({
    type: SET_TOGGLE_SCREEN,
    payload,
})

export const setListenersCount = (payload) => ({
    type: SET_LISTENER_COUNT,
    payload,
})

export const setTrackCount = (payload) => ({
    type: SET_TRACK_COUNT,
    payload,
})

export const setPlaylistDetails = (payload) => ({
    type: SET_PLAYLIST_DETAILS,
    payload,
})

export const resetPlaylistDetails = () => ({
    type: RESET_PLAYLIST_DETAILS,
})

export const setCurrentPlayingCatalog = (payload) => ({
    type: SET_CURRENT_PLAYING_CATALOG,
    payload,
})

export const setJoinedLiveroom = (payload) => ({
    type: SET_JOINED_LIVEROOM,
    payload,
})

export const setCurrentLiveroomParticipantCount = (payload) => ({
    type: SET_CURRENT_LIVEROOM_PARTICIPANT_COUNT,
    payload,
})

export const setCurrentLiveSpeakers = (payload) => ({
    type: SET_CURRENT_LIVE_SPEAKERS,
    payload,
})

export const setCurrentLiveroom = (payload) => ({
    type: SET_CURRENT_LIVEROOM,
    payload,
})

export const setPlayingRadioListenersCount = (payload) => ({
    type: SET_PLAYING_RADIO_LISTENER_COUNT,
    payload,
})

export const setShowLoginPrompt = (payload) => ({
    type: SET_SHOW_LOGIN_PROMPT,
    payload,
})

export const setCurrentContentId = (payload) => ({
    type: SET_CURRENT_CONTENT_ID,
    payload,
})

export const setElapsedTime = (payload) => ({
    type: SET_ELAPSED_TIME,
    payload,
})
